
import { Result } from 'antd';
import './index.css';
import React from 'react';

import { ResultStatusType } from 'antd/lib/result';
import { Icon404 } from './404';
import { Icon403 } from './403';
import { Icon500 } from './500';
import { Success } from './Success';
import { Error } from './Error';
import { Warning } from './Warning';


const getIcon = (status: ResultStatusType) => {
    // ExceptionMap[403] = test;
    switch(status){
        case "403": return <Icon403 />;
        case "404": return <Icon404 />;
        case "500": return <Icon500 />;
        case "success": return <Success />;
        case "error": return <Error />;
        case "warning": return <Warning />;
        default: return;
    }
}

const getStatus = (status: ResultStatusType) => {
    if(status != "error" && status != "success" && status != "warning" ){
        return "info";
    }
    else return status;
}

const ResultWrapper = (props: any) =>{        
    return(
        <Result 
            icon={getIcon(props.status)}    
            {...props}            
            status={getStatus(props.status)}
        />
    )
}

export default ResultWrapper;