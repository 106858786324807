
import { InputNumber } from 'antd';
import './index.css';
import {UpOutlined, DownOutlined} from "@p4ma/icons";

const InputNumberWrapper = (props: any) => {
    return (
        <InputNumber controls={{upIcon: <UpOutlined/>, downIcon: <DownOutlined/> }} {...props}>
            {props.children}
        </InputNumber>
    )
}
export default InputNumberWrapper;