import React from 'react';

export const Icon403: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z" fill="#F8FAFC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1256 39.68C34.1256 34.76 36.8856 32 40.1256 32C43.3656 32 46.1256 34.76 46.1256 39.68C46.1256 44.6 43.3656 47.36 40.1256 47.36C36.8856 47.36 34.1256 44.6 34.1256 39.68ZM40.1256 45.32C41.9496 45.32 43.8456 43.4 43.8456 39.68C43.8456 35.96 41.9496 34.04 40.1256 34.04C38.3016 34.04 36.4056 35.96 36.4056 39.68C36.4056 43.4 38.3016 45.32 40.1256 45.32Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 44.24V42.32L28.36 32.12H31V42.08H32.68V44.24H31V47.24H28.72V44.24H22ZM24.64 42.08H28.72V35.48L24.64 42.08Z" fill="#647D94"/>
        <path d="M49.7331 43.16H47.4531C47.5731 45.44 49.4691 47.36 52.6131 47.36C55.7331 47.36 57.7731 45.32 57.7731 43.04C57.7731 41.672 57.1731 40.712 56.5731 40.136C56.2131 39.8 55.8291 39.536 55.3731 39.32C55.7331 39.104 56.0451 38.864 56.3331 38.576C56.8131 38.072 57.2931 37.28 57.2931 36.2C57.2931 34.016 55.2531 32 52.4931 32C49.6131 32 47.6931 33.92 47.5731 36.2H49.8531C49.9731 34.976 50.9331 34.04 52.4931 34.04C54.0531 34.04 55.0131 34.976 55.0131 36.32C55.0131 37.544 54.0531 38.48 52.4931 38.48H51.5331V40.52H52.6131C54.4371 40.52 55.4931 41.576 55.4931 42.92C55.4931 44.264 54.4371 45.32 52.6131 45.32C50.9331 45.32 49.8531 44.24 49.7331 43.16Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM79 40C79 61.5391 61.5391 79 40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40Z" fill="#647D94"/>
    </svg>
);