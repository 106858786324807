import React from 'react';

export const Icon404: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z" fill="#F8FAFC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1256 39.68C34.1256 34.76 36.8856 32 40.1256 32C43.3656 32 46.1256 34.76 46.1256 39.68C46.1256 44.6 43.3656 47.36 40.1256 47.36C36.8856 47.36 34.1256 44.6 34.1256 39.68ZM40.1256 45.32C41.9496 45.32 43.8456 43.4 43.8456 39.68C43.8456 35.96 41.9496 34.04 40.1256 34.04C38.3016 34.04 36.4056 35.96 36.4056 39.68C36.4056 43.4 38.3016 45.32 40.1256 45.32Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 44.24V42.32L28.36 32.12H31V42.08H32.68V44.24H31V47.24H28.72V44.24H22ZM24.64 42.08H28.72V35.48L24.64 42.08Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.2131 42.32V44.24H53.9331V47.24H56.2131V44.24H57.8931V42.08H56.2131V32.12H53.5731L47.2131 42.32ZM53.9331 42.08H49.8531L53.9331 35.48V42.08Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM79 40C79 61.5391 61.5391 79 40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40Z" fill="#647D94"/>
    </svg>
);