import React from 'react';

export const Icon500: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z" fill="#F8FAFC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9972 39.68C32.9972 34.76 35.7572 32 38.9972 32C42.2372 32 44.9972 34.76 44.9972 39.68C44.9972 44.6 42.2372 47.36 38.9972 47.36C35.7572 47.36 32.9972 44.6 32.9972 39.68ZM38.9972 45.32C40.8212 45.32 42.7172 43.4 42.7172 39.68C42.7172 35.96 40.8212 34.04 38.9972 34.04C37.1732 34.04 35.2772 35.96 35.2772 39.68C35.2772 43.4 37.1732 45.32 38.9972 45.32Z" fill="#647D94"/>
        <path d="M21 43.16H23.28C23.4 44.24 24.48 45.32 26.04 45.32C27.72 45.32 29.04 44 29.04 42.2C29.04 40.4 27.72 39.08 26.04 39.08C25.08 39.08 24.384 39.536 23.928 39.992C23.664 40.256 23.448 40.568 23.28 40.88H21.36L22.32 32.12H30.36V34.28H24.264L23.88 37.88C24.048 37.736 24.264 37.592 24.528 37.472C24.936 37.256 25.536 37.04 26.28 37.04C29.064 37.04 31.32 39.32 31.32 42.2C31.32 45.08 29.016 47.36 26.04 47.36C23.04 47.36 21.12 45.44 21 43.16Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M52.6847 32C49.4447 32 46.6847 34.76 46.6847 39.68C46.6847 44.6 49.4447 47.36 52.6847 47.36C55.9247 47.36 58.6847 44.6 58.6847 39.68C58.6847 34.76 55.9247 32 52.6847 32ZM56.4047 39.68C56.4047 43.4 54.5087 45.32 52.6847 45.32C50.8607 45.32 48.9647 43.4 48.9647 39.68C48.9647 35.96 50.8607 34.04 52.6847 34.04C54.5087 34.04 56.4047 35.96 56.4047 39.68Z" fill="#647D94"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM79 40C79 61.5391 61.5391 79 40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40Z" fill="#647D94"/>
    </svg>
);