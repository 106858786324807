import React from 'react';

export const Warning: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3548_40926)">
            <path d="M37.2534 7.56295C38.4766 5.47902 41.5234 5.47902 42.7466 7.56295L79.5671 70.292C80.7941 72.3823 79.2669 75 76.8204 75H3.17955C0.733099 75 -0.794065 72.3823 0.432907 70.292L37.2534 7.56295Z" fill="#FFFAF1"/>
            <path d="M39 56C39 56.5523 39.4477 57 40 57C40.5523 57 41 56.5523 41 56V55.5C41 54.9477 40.5523 54.5 40 54.5C39.4477 54.5 39 54.9477 39 55.5V56Z" fill="#FFA800"/>
            <path d="M39 50.5C39 51.0523 39.4477 51.5 40 51.5C40.5523 51.5 41 51.0523 41 50.5V38C41 37.4477 40.5523 37 40 37C39.4477 37 39 37.4477 39 38V50.5Z" fill="#FFA800"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2534 7.56295C38.4766 5.47902 41.5234 5.47902 42.7466 7.56295L79.5671 70.292C80.7941 72.3823 79.2669 75 76.8204 75H3.17955C0.733099 75 -0.794065 72.3823 0.432907 70.292L37.2534 7.56295ZM41.8842 8.06916L78.7047 70.7982C79.5294 72.2031 78.5157 74 76.8204 74H3.17955C1.48427 74 0.470645 72.2031 1.29531 70.7982L38.1158 8.06916C38.9525 6.64361 41.0475 6.64361 41.8842 8.06916Z" fill="#FFA800"/>
        </g>
        <defs>
            <clipPath id="clip0_3548_40926">
                <rect width="80" height="80" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);