import React from 'react';

export const Success: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3548_41032)">
            <path d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z" fill="#F8FFFA"/>
            <path d="M49.7752 33.6316C50.1241 33.2035 50.0598 32.5736 49.6317 32.2247C49.2035 31.8759 48.5736 31.9402 48.2248 32.3683L37.9489 44.9798L31.7311 38.3178C31.3542 37.9141 30.7214 37.8923 30.3177 38.2691C29.9139 38.6459 29.8921 39.2787 30.2689 39.6825L37.2689 47.1825C37.4661 47.3937 37.7449 47.5093 38.0336 47.4996C38.3223 47.4899 38.5928 47.3558 38.7752 47.1319L49.7752 33.6316Z" fill="#00AA42"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40ZM40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40C79 61.5391 61.5391 79 40 79Z" fill="#00AA42"/>
        </g>
        <defs>
            <clipPath id="clip0_3548_41032">
                <rect width="80" height="80" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);