import React from 'react';

export const Error: React.FC = () => ( 
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3548_41068)">
            <path d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z" fill="#FFF8FA"/>
            <path d="M49.7071 48.2929C50.0976 48.6834 50.0976 49.3166 49.7071 49.7071C49.3166 50.0976 48.6834 50.0976 48.2929 49.7071L40 41.4142L31.7071 49.7071C31.3166 50.0976 30.6834 50.0976 30.2929 49.7071C29.9024 49.3166 29.9024 48.6834 30.2929 48.2929L38.5858 40L30.2929 31.7071C29.9024 31.3166 29.9024 30.6834 30.2929 30.2929C30.6834 29.9024 31.3166 29.9024 31.7071 30.2929L40 38.5858L48.2929 30.2929C48.6834 29.9024 49.3166 29.9024 49.7071 30.2929C50.0976 30.6834 50.0976 31.3166 49.7071 31.7071L41.4142 40L49.7071 48.2929Z" fill="#CC0338"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40ZM40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40C79 61.5391 61.5391 79 40 79Z" fill="#CC0338"/>
        </g>
        <defs>
            <clipPath id="clip0_3548_41068">
                <rect width="80" height="80" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);